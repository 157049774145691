/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Dynamic from 'next/dynamic';

import { IntlProvider } from 'react-intl';
import { CssBaseline } from '@mui/material';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { storeWrapper } from 'store';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';

import NextNProgress from 'nextjs-progressbar';
import theme from 'styles/customTheme';
import 'react-toastify/dist/ReactToastify.css';
import { WebAppProvider } from 'providers/WebAppProvider';
import { getCookie } from 'cookies-next';

const GTM = Dynamic(() => import('components/GTMScriptComponent'), {
  ssr: false,
});

const AffiliateComponent = Dynamic(
  () => import('components/ParceirosPromoV2Component'),
  {
    ssr: false,
  }
);

const AffiliateComponentV3 = Dynamic(
  () => import('components/ParceirosPromoV3Component'),
  {
    ssr: false,
  }
);

const PAPAffiliateComponent = Dynamic(() => import('components/PAPComponent'), {
  ssr: false,
});

const queryClient = new QueryClient();

App.getInitialProps = async (context) => {
  const { ctx } = context;

  const runningInWebViewCookie = getCookie('runningInWebView', {
    req: ctx.req,
    res: ctx.res,
  });

  const runningInIOSCookie = getCookie('runningInIOS', {
    req: ctx.req,
    res: ctx.res,
  });

  const runningInAndroidCookie = getCookie('runningInAndroid', {
    req: ctx.req,
    res: ctx.res,
  });

  const runningInWebView = runningInWebViewCookie === 'true';
  const runningInIOS = runningInIOSCookie === 'true';
  const runningInAndroid = runningInAndroidCookie === 'true';

  return { runningInWebView, runningInIOS, runningInAndroid };
};

function App({
  Component,
  pageProps,
  router,
  runningInWebView,
  runningInIOS,
  runningInAndroid,
}) {
  const { user } = pageProps;

  const [extractedURl] = router.asPath.match(/.+(?=\?)|.+/g);

  const canonicalPath = extractedURl !== '/' ? extractedURl : '';

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) jssStyles.parentElement.removeChild(jssStyles);
  }, []);

  return (
    <>
      <Head>
        <title>Fluencypass - O ciclo completo para você falar inglês</title>
        <link
          rel="shortcut icon"
          href="https://res.cloudinary.com/intercambiodireto-com/image/upload/c_scale,h_16,w_16,f_auto/v1604515879/favicon_wmakiq.ico"
          type="image/x-icon"
        />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/icon-512x512.png" />
        <link rel="canonical" href={`${process.env.HOST}${canonicalPath}`} />
        <link href="https://res.cloudinary.com/intercambiodireto-com/image/upload/f_auto/v1611326916/Others/backgrounds/screenshot-min_sa08vj" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <meta name="language" content="pt-BR" />
        <meta name="country" content="BRA" />
        <meta name="rights" content="Fluencypass.com" />
        <meta property="fb:app_id" content="1611242345800811" />
        <meta property="og:site_name" content="Fluencypass.com" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fluencypass.com/" />
        <meta
          name="facebook-domain-verification"
          content="ve82gmijvg6mnoccfivsvprmw3rdfk"
        />
        <meta
          name="facebook-domain-verification"
          content="kzqd9kxlgyskyr8fms71b9pn45jiqn"
        />
        <meta property="og:image" content={`${process.env.HOST}/api/og`} />
        <meta name="robots" content="follow, index" />
        <meta
          name="description"
          content="O único método do mundo com o ciclo completo para você falar inglês. Aulas, conversação e intercâmbio em uma experiência única."
        />
        <meta
          property="og:title"
          content="Fluencypass - O ciclo completo para você falar inglês"
          key="title"
        />
        <meta
          property="og:description"
          content="O único método do mundo com o ciclo completo para você falar inglês. Aulas, conversação e intercâmbio em uma experiência única."
          key="description"
        />
      </Head>

      {JSON.parse(process.env.PRODUCTION) && <GTM />}

      <QueryClientProvider client={queryClient}>
        <IntlProvider locale="pt-BR">
          <MaterialThemeProvider theme={theme}>
            <CssBaseline />

            <UserProvider user={user}>
              <WebAppProvider
                runningInWebView={runningInWebView}
                runningInIOS={runningInIOS}
                runningInAndroid={runningInAndroid}
              >
                <Component {...pageProps} />
              </WebAppProvider>
            </UserProvider>
          </MaterialThemeProvider>
        </IntlProvider>

        <ReactQueryDevtools />
      </QueryClientProvider>

      <AffiliateComponent />
      <AffiliateComponentV3 />
      <PAPAffiliateComponent />

      <ToastContainer
        autoClose={3000}
        closeOnClick
        hideProgressBar
        position="bottom-left"
      />

      <NextNProgress
        color="#ED6A6D"
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
      />
    </>
  );
}

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default storeWrapper.withRedux(App);
